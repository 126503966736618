import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <h1>
      <span>Tu powstaje coś pięknego.</span>
      <br></br>
      <span>Something beautiful is being created here.</span>
    </h1>
  );
}

export default App;
